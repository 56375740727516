<template>
    <section>
        <div class="row mx-0 border-bottom align-items-center" style="height:55px;">
            <div class="col-auto f-600 text-black">
                {{ carpeta.nombre }}
            </div>
            <button v-if="carpeta.id" type="button" class="btn-general-small mr-2 ml-auto" @click="crearFeature">
                Nueva Feautre Flags
            </button>
            <el-tooltip v-if="carpeta.id" content="Editar" placement="bottom" effect="light" class="mr-2">
                <div class="btn-action" @click="irEditar">
                    <i class="icon-pencil-outline f-20 cr-pointer" />
                </div>
            </el-tooltip>
            <el-tooltip v-if="carpeta.id" content="Eliminar" placement="bottom" effect="light">
                <div class="btn-action" @click="confirmarEliminar">
                    <i class="icon-trash-empty f-20 cr-pointer" />
                </div>
            </el-tooltip>
            <!-- <div  class="col-auto px-2">
            </div> -->
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <el-table
                :data="tableData"
                class="table-feature-flag"
                style="width: 100%"
                >
                    <el-table-column
                    prop="nombre"
                    label="Nombre"
                    min-width="200"
                    />
                    <el-table-column
                    prop="flag"
                    label="Nombre sin espacios"
                    min-width="200"
                    />
                    <el-table-column
                    prop="descripcion"
                    label="Descripción"
                    min-width="200"
                    />
                    <el-table-column
                    prop="texto"
                    label="Texto de apoyo"
                    min-width="200"
                    />
                    <el-table-column
                    label="Estado"
                    min-width="450"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0">
                                <div class="col" @change="updateEstado(scope.row.id, scope.row.estado)">
                                    <div class="row mx-0 mb-2">
                                        <el-radio v-model="scope.row.estado" class="radio-orange" :label="1">
                                            Enable
                                        </el-radio>
                                    </div>
                                    <div class="row mx-0 mb-2">
                                        <el-radio v-model="scope.row.estado" class="radio-orange" :label="2">
                                            Full Disable
                                        </el-radio>
                                    </div>
                                    <div class="row mx-0 mb-2">
                                        <el-radio v-model="scope.row.estado" class="radio-orange" :label="3">
                                            Disable + Text
                                        </el-radio>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <el-tooltip placement="bottom" content="Editar" effect="light">
                                        <i class="icon-pencil-outline f-22 mr-2 cr-pointer" @click="irEditarFlag(scope.row.id)" />
                                    </el-tooltip>
                                    <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                        <i class="icon-trash-empty f-22 cr-pointer" @click="confirmarEliminarFlag(scope.row.id)" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-feature ref="modalCrearFeature" @nuevo="getData" />
        <modal-editar-feature ref="modalEditarFeature" @actualizo="getData" />
        <modal ref="modalEliminarCarpeta" no-aceptar adicional="Eliminar" titulo="Eliminar carpeta" @adicional="irEliminar">
            <div class="row mx-0 justify-center mb-3">
                <div class="col-8">
                    <p class="f-15 pl-3">¿Está seguro de eliminar esta carpeta?</p>
                </div>
            </div>
        </modal>
        <modal ref="modalEliminarFlag" no-aceptar adicional="Eliminar" titulo="Eliminar flag" @adicional="irEliminarFlag">
            <div class="row mx-0 justify-center mb-3">
                <div class="col-8">
                    <p class="f-15 pl-3">¿Está seguro de eliminar este flag?</p>
                </div>
            </div>
        </modal>
        <modal ref="modalEditarCarpeta" no-aceptar adicional="Guardar" titulo="Editar Carpeta" @adicional="putCarpeta">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center mb-3">
                    <div class="col-8">
                        <p class="f-15 pl-3">Nombre</p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="Nombre">
                            <el-input v-model="nombre" placeholder="Nombre carpeta" class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>

<script>
import Service from '~/services/faqs/faqs';

export default {
    components: {
        modalCrearFeature: () => import('../partials/modalCrearFeature.vue'),
        modalEditarFeature: () => import('../partials/modalEditarFeature.vue'),
    },
    props:{
        carpeta:{
            type: Object,
            default:() => {},
        }
    },
    data(){
        return {
            tableData: [],
            idFlag: null,
            nombre: null,
        }
    },
    watch:{
        carpeta(){
            this.getData()
        }
    },
    mounted(){
        if(!_.isEmpty(this.carpeta.id)){
            this.getData();
        } else {
            setTimeout(() => {
                this.getData();
            },500);
        }
    },
    methods: {
        async getData(){
            try {
                if(_.isEmpty(this.carpeta)){
                    this.tableData = [];
                    this.idFlag = null;
                    this.nombre = null;
                    return;
                }
                const {data} = await Service.getFeatureFlags(this.carpeta.id);
                this.tableData = data.data;
            } catch(e){
                this.error_catch(e)
            }
        },
        irEditar(){
            this.nombre = this.carpeta.nombre;
            this.$refs.modalEditarCarpeta.toggle();
        },
        async putCarpeta(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const model = {
                    tipo: this.carpeta.app,
                    nombre: this.nombre
                };
                const {data} = await Service.putCarpeta(this.carpeta.id, model)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar', data.carpetas);
                this.nombre = null;
            } catch(e){
                this.error_catch(e)
            } finally{
                this.$refs.modalEditarCarpeta.toggle();
            }
        },
        confirmarEliminar(){
            this.$refs.modalEliminarCarpeta.toggle();
        },
        async irEliminar(){
            try {
                const {data} = await Service.deleteCarpeta(this.carpeta.id);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar', null);
            } catch(e){
                this.error_catch(e)
            } finally{
                this.$refs.modalEliminarCarpeta.toggle();
            }
        },
        confirmarEliminarFlag(id){
            this.idFlag = id;
            this.$refs.modalEliminarFlag.toggle();
        },
        async irEliminarFlag(){
            try {
                const {data} = await Service.deleteFlag(this.idFlag);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.idFlag = null;
                this.getData();
                this.$refs.modalEliminarFlag.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },
        async updateEstado(id, estado){
            try {
                const model = {
                    estado: estado
                };
                const {data} = await Service.updateEstadoFlag(id, model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
            } catch(e){
                this.error_catch(e)
            }
        },
        irEditarFlag(id){
            let data = this.tableData.find((element) => element.id === id);
            this.$refs.modalEditarFeature.toggle(data);
        },
        crearFeature(){
            this.$router.push({name: "manager.features-flags.crear-flag", params: {id: this.carpeta.id}});
        }
    }
}
</script>
<style lang="scss" scoped>
.icon-pencil-outline:hover{
    color: #000000;
}
.icon-trash-empty:hover{
    color: #000000;
}
.btn-nueva{
    border-radius: 12px;
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
    background: linear-gradient(to right, #FF9D32, #FF2525);
    &:focus{
        outline: none;
    }
}
</style>