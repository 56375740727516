import { render, staticRenderFns } from "./detailFolder.vue?vue&type=template&id=034710cb&scoped=true"
import script from "./detailFolder.vue?vue&type=script&lang=js"
export * from "./detailFolder.vue?vue&type=script&lang=js"
import style0 from "./detailFolder.vue?vue&type=style&index=0&id=034710cb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034710cb",
  null
  
)

export default component.exports